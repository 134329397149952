import { GetServerSidePropsContext } from 'next'

import jwtDecode from 'jwt-decode'
import ServerCookie from 'next-cookies'

import { isServer } from 'utils/api/helpers'
import storage from 'utils/storage'
import { User } from 'utils/user'

export interface DecodedToken extends User {
    readonly email: string
    readonly exp: number,
    readonly roles: string[]
}

export const STORAGE_JWT_TOKEN_KEY = 'jwt-token'

export const removeToken = (): void => storage.remove(STORAGE_JWT_TOKEN_KEY, 'cookies')

export const getToken = (): string => storage.get(STORAGE_JWT_TOKEN_KEY, 'cookies')

export const getTokenFromServer = (props: unknown): string => ServerCookie(props)[STORAGE_JWT_TOKEN_KEY]

export const decodeToken = (token: string): DecodedToken | undefined => {
    if (!token) return undefined
    try {
        return jwtDecode(token) as DecodedToken
    } catch {
        return undefined
    }
}

export const expiresAt = (token: string): Date => {
    const decoded = decodeToken(token)
    return decoded ? new Date(decoded.exp * 1000) : new Date(0)
}

export const isExpired = (token: string): boolean => new Date() > expiresAt(token)

export const isValid = (token: string): boolean => !!decodeToken(token) && !isExpired(token)

export const getTokenUniversal = (context?: GetServerSidePropsContext): string | null => {
    if (isServer && context) {
        // ServerSide
        return ServerCookie(context)[STORAGE_JWT_TOKEN_KEY] || null
    }
    // ClientSide
    return getToken()
}
export const getTokenFromContext = getTokenUniversal
