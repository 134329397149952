import { GetServerSidePropsContext } from 'next/types'

import ServerCookie from 'next-cookies'

import { STORAGE_LOCALE_KEY } from 'utils/i18n'
import storage from 'utils/storage'

export const isServer = (): boolean => typeof window === 'undefined'

export const extractContextMessage = (message: string): ContextMessage => {
    try {
        return JSON.parse(message)
    } catch (exception) {
        return null
    }
}
export const getViolations = (error: Error): Violations => {
    const { response } = error
    return response?.data?.violations
}

export const getLocale = (context?:GetServerSidePropsContext): string => {
    if (isServer && context) {
        return ServerCookie(context)[STORAGE_LOCALE_KEY]
    }
    const locale = storage.get(STORAGE_LOCALE_KEY, 'cookies') || 'en'
    return locale
}
