import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import storage from '../storage'

import resources from './resources'

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        saveMissing: true,
        fallbackLng: ['fr'],
        supportedLngs: ['en', 'fr'],
        defaultNS: 'translations',
        fallbackNS: 'translations',
        ns: ['translations', 'additional', 'words'],
        debug: false,
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ['querystring', 'localStorage', 'navigator'],
            caches: ['localStorage', 'cookie'],
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
            lookupSessionStorage: 'i18nextLng',
            lookupQuerystring: 'locale',
            interpolation: {
                escapeValue: false
            }
        },
        appendNamespaceToMissingKey: true
    })

export const languages = i18n.options.supportedLngs.filter(language => language !== 'cimode')

export const STORAGE_LOCALE_KEY = 'i18next'

export const getLocal = () => storage.get(STORAGE_LOCALE_KEY, 'cookies')
export default i18n
